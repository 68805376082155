const data = [
  {
    index: '#1',
    latitude: 50.07122048704789,
    longitude: 14.439507755725478,
    name: 'Cheesecake time',
    address: 'Americká 2, Praha 2',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/cheesecake.time/',
    facebook: '',
    web: '',
    review: 'https://www.instagram.com/p/CeohDIzsg3a/',
    preview: '../previews/01.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[ "mat",  "inside", null]',
  },
  {
    index: '#2',
    latitude: 50.087195390774184,
    longitude: 14.429918442472008,
    name: 'Hlína (Kampus Hybernská)',
    address: 'Hybernská 4, Praha 1',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/kavarna.hlina/',
    facebook: '',
    web: '',
    review: 'https://www.instagram.com/p/Ceg9xGFM_bo/',
    preview: '../previews/02.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[ "mat",  "inside", "outside"]',
  },
  {
    index: '#3',
    latitude: 50.027458550759015,
    longitude: 14.59381452678034,
    name: 'Block Café',
    address: 'K Dálnici 61, Praha 22',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/blockuhrineves/',
    facebook: '',
    web: '',
    review: 'https://www.instagram.com/p/Cey8BHVMo32/',
    preview: '../previews/03.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: 'null',
    outside: '"outside"',
    filters: '[  "barrier-free",  "outside"]',
  },
  {
    index: '#4',
    latitude: 50.089611715556664,
    longitude: 14.427740253772464,
    name: 'Pizza Nuova',
    address: 'Revoluční 1, Praha 1',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/pizza_nuova/',
    facebook: 'https://www.facebook.com/pizzanuova.ambiente',
    web: '',
    review: 'https://www.instagram.com/p/CeidfFfMQj2/',
    preview: '../previews/04.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station", "mat", "barrier-free", "inside", null]',
  },
  {
    index: '#5',
    latitude: 50.08823250244053,
    longitude: 14.43000132678235,
    name: 'Cacao',
    address: 'V Celnici 1031/4, Praha 1',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/cacaoprague/',
    facebook: 'https://www.facebook.com/cacaopraha',
    web: '',
    review: 'https://www.instagram.com/p/CerE9unM7Kz/',
    preview: '../previews/05.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station", "mat",  "inside", null]',
  },
  {
    index: '#6',
    latitude: 50.06445215060716,
    longitude: 14.443655115131497,
    name: 'Zrno Zrnko',
    address: 'Čestmírova 17a, Praha 4',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/zrno_zrnko/',
    facebook: 'https://www.facebook.com/Zrnozrnko/',
    web: 'https://www.zrnozrnko.cz/nusle/',
    review: 'https://www.instagram.com/p/CetKCzEMXtt/',
    preview: '../previews/06.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '["changing-station",  "barrier-free", "inside", "outside"]',
  },
  {
    index: '#7',
    latitude: 49.99156094071551,
    longitude: 14.660282613284126,
    name: 'Říčanský pivovar',
    address: 'Olivova 246/23, Říčany',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/ricanskypivovar',
    web: '',
    review: 'https://www.instagram.com/p/CgG4FtkLsov/',
    preview: '../previews/07.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '["changing-station",    "outside"]',
  },
  {
    index: '#8',
    latitude: 50.08272242432239,
    longitude: 14.424635511654554,
    name: 'Bazén a bar Juliš',
    address: 'Václavské nám. 782, Praha 1',
    categoryName: 'Herna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/bazenjulis',
    web: 'https://www.bazenjulis.cz/',
    review: 'https://www.instagram.com/p/Cek6xdQMFPn/',
    preview: '../previews/08.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station", "mat",  "inside", null]',
  },
  {
    index: '#9',
    latitude: 50.040303700616526,
    longitude: 14.436052297945764,
    name: 'Chaloupka',
    address: 'Antala Staška 327/16, Praha 4',
    categoryName: 'Herna',
    category: 'playroom',
    instagram: '',
    facebook: 'https://www.facebook.com/Chaloupkaherna/',
    web: '',
    review: '',
    preview: '../previews/09.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station", "mat",  "inside", null]',
  },
  {
    index: '#10',
    latitude: 50.023502169759176,
    longitude: 14.605811793368943,
    name: 'U Splavu',
    address: 'U Nadýmače 200/37, Praha 22',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/u_splavu_/',
    facebook: 'https://www.facebook.com/usplavu',
    web: '',
    review: 'https://www.instagram.com/reel/Ce2-QsvgZ0k/',
    preview: '../previews/10.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '["changing-station", "mat",   "outside"]',
  },
  {
    index: '#11',
    latitude: 50.11333838113994,
    longitude: 14.467535088300222,
    name: 'Restaurace Koráb',
    address: 'Krejčího 2261, Praha 8',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/restauraceapenzionkorab',
    web: 'http://www.restauracekorab.cz/',
    review: 'https://www.instagram.com/p/Ce6GqNos4IA/',
    preview: '../previews/11.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: 'null',
    outside: '"outside"',
    filters: '["changing-station",  "barrier-free",  "outside"]',
  },
  {
    index: '#12',
    latitude: 50.79137905909804,
    longitude: 15.218751743990376,
    name: 'Hotel Peklo',
    address: 'Josefův Důl 960,  Josefův Důl',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/hotelpeklo/?hl=cs',
    facebook: 'https://www.facebook.com/hotelpekloprodeti',
    web: '',
    review: '',
    preview: '../previews/12.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: 'null',
    outside: '"outside"',
    filters: '["changing-station", "mat", "barrier-free",  "outside"]',
  },
  {
    index: '#13',
    latitude: 50.78789308548892,
    longitude: 15.214508770980332,
    name: 'Perla Jizery',
    address: 'Josefův Důl 187, Josefův Důl',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/hotelperlajizery/?hl=cs',
    facebook: 'https://www.facebook.com/HotelPerlaJizery',
    web: '',
    review: '',
    preview: '../previews/13.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[   "inside", "outside"]',
  },
  {
    index: '#14',
    latitude: 50.79498183137402,
    longitude: 15.163645870980593,
    name: 'Prezidentská chata',
    address: 'Janov nad Nisou 524, Bedřichov',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/prezidentska/?hl=cs',
    facebook: 'https://www.facebook.com/prezidentska',
    web: '',
    review: '',
    preview: '../previews/14.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[   "inside", "outside"]',
  },
  {
    index: '#15',
    latitude: 50.02984256207178,
    longitude: 14.604995656107606,
    name: 'Pivovarská Restaurace',
    address: 'K Sokolovně 38, Praha 22',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/PivovarskaRestaurace',
    web: 'https://restaurantpivovarska.cz/',
    review: '',
    preview: '../previews/15.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#16',
    latitude: 50.049910474417764,
    longitude: 14.440057515130944,
    name: 'Šalanda Pankrác',
    address: 'Na Strži 2097/63,  Praha 4',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.restauracesalanda.cz/cs/salanda/pankrac/',
    facebook: 'https://www.facebook.com/salandapankrac',
    web: '',
    review: '',
    preview: '../previews/16.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#17',
    latitude: 50.07215928149701,
    longitude: 14.436892811441824,
    name: 'Klempírna',
    address: 'Záhřebská 317,  Praha 2',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/klempirna',
    web: 'http://www.klempirna-restaurant.cz/',
    review: '',
    preview: '../previews/17.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#18',
    latitude: 50.03086870580955,
    longitude: 14.600757546019874,
    name: 'Čínská Restaurace Dragon',
    address: 'Přátelství 142, Praha 22',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/%C4%8C%C3%ADnsk%C3%A1-Restaurace-Dragon-109188761644736',
    web: 'https://dragonrestaurace.cz/',
    review: '',
    preview: '../previews/18.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[   "inside", "outside"]',
  },
  {
    index: '#19',
    latitude: 50.10078366822635,
    longitude: 14.4242013,
    name: 'Bahn Mi Makers',
    address: 'Letenské nám. 5, Praha 7',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/banhmimakers/?hl=cs',
    facebook: 'https://www.facebook.com/banhmimakers',
    web: '',
    review: 'https://www.instagram.com/p/Cfdpo76IFdN/',
    preview: '../previews/19.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[ "mat",  "inside", null]',
  },
  {
    index: '#20',
    latitude: 50.10776853213939,
    longitude: 14.453374053972164,
    name: 'Pod Párou',
    address: 'Jankovcova 1037/49, Praha 7',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/PodParouRestaurace/',
    web: '',
    review: 'https://www.instagram.com/p/CidAdBNjC1s/',
    preview: '../previews/20.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#21',
    latitude: 50.100231719118945,
    longitude: 14.425671786764545,
    name: 'Erhartova cukrárna',
    address: 'M. Horákové 387/56,  Praha 7',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/erhartovacukrarna/?hl=cs',
    facebook:
      'https://www.facebook.com/Erhartova-Cukr%C3%A1rna-ofici%C3%A1ln%C3%AD-str%C3%A1nka-156972371005007',
    web: '',
    review: 'https://www.instagram.com/p/CfYa9UwoE0C/',
    preview: '../previews/21.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#22',
    latitude: 50.08923786858124,
    longitude: 14.427463856079124,
    name: 'Kulíškov',
    address: 'Náměstí Republiky 656, Praha 1',
    categoryName: 'Herna',
    category: 'playroom',
    instagram: '',
    facebook: 'https://www.facebook.com/kuliskov/?fref=ts',
    web: '',
    review: '',
    preview: '../previews/22.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#23',
    latitude: 50.0794885280509,
    longitude: 14.490881609649762,
    name: 'ROCHER CAFÉ Výběr',
    address: 'Révová 14, Praha 10',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/rochercafe/?hl=cs',
    facebook: 'https://www.facebook.com/rochercafevyber',
    web: '',
    review: 'https://www.instagram.com/p/CfzUJkJIN6N/',
    preview: '../previews/23.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[ "mat",  "inside", "outside"]',
  },
  {
    index: '#24',
    latitude: 50.074548013090535,
    longitude: 14.44028545481177,
    name: 'SKÁKALPES',
    address: 'Francouzská 15, Praha 2',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/presoves/',
    facebook: 'https://www.facebook.com/PRESOVES',
    web: '',
    review: 'https://www.instagram.com/p/Ce0qA47s07E/',
    preview: '../previews/24.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[ "mat", "barrier-free", "inside", "outside"]',
  },
  {
    index: '#25',
    latitude: 50.0778790945742,
    longitude: 14.456390313286958,
    name: 'Erhartova cukrárna',
    address: 'Vinohradská 2022/125, Praha 3-Vinohrady',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook:
      'https://www.facebook.com/Erhartova-Cukr%C3%A1rna-ofici%C3%A1ln%C3%AD-str%C3%A1nka-156972371005007',
    web: '',
    review: '',
    preview: '../previews/25.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#26',
    latitude: 50.04644143159999,
    longitude: 14.549321244966725,
    name: 'Hostivar 1',
    address: 'Lochotínská 656, Praha 15',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/pivovarhostivar/',
    facebook: 'https://www.facebook.com/Hostivar',
    web: '',
    review: 'https://www.instagram.com/p/CfHdkUKLPIM/',
    preview: '../previews/26.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: 'null',
    outside: '"outside"',
    filters: '[  "barrier-free",  "outside"]',
  },
  {
    index: '#27',
    latitude: 50.76091935243121,
    longitude: 14.550910231022732,
    name: 'La Piccolina',
    address: 'Dvořákova 359, Nový Bor',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/la.piccolina.nb',
    web: '',
    review: 'https://www.instagram.com/p/Ce_K8-sso2m/',
    preview: '../previews/27.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#28',
    latitude: 50.097105745657245,
    longitude: 14.40760596911268,
    name: 'Cafe Pointa',
    address: 'Na Valech 2, Praha 6',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/cafe_bistro_pointa/',
    facebook: 'https://www.facebook.com/CafePointa',
    web: '',
    review: 'https://www.instagram.com/p/CfUdlpnJG4M/',
    preview: '../previews/28.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '[ "mat", "barrier-free", "inside", null]',
  },
  {
    index: '#29',
    latitude: 50.100453786620356,
    longitude: 14.427629092039748,
    name: 'Amato Gelateria & Caffe',
    address: 'Kamenická 30, Praha 7-Holešovice',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/amato_gelateria/',
    facebook: 'https://www.facebook.com/Gelateria-Caff%C3%A9-Amato-576442076187717',
    web: 'http://www.gelateriaamato.cz/',
    review: 'https://www.instagram.com/p/CfRUZhGLCKn/',
    preview: '../previews/29.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#30',
    latitude: 50.10526721101891,
    longitude: 14.422156540277934,
    name: 'Vozovna',
    address: 'Královská obora 2, Praha 7',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/vozovnastromovka',
    web: '',
    review: 'https://www.instagram.com/p/CglaGk_sAHd/',
    preview: '../previews/30.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: 'null',
    outside: '"outside"',
    filters: '["changing-station",  "barrier-free",  "outside"]',
  },
  {
    index: '#31',
    latitude: 50.0740028659847,
    longitude: 14.490988584451879,
    name: 'Kávový magnát Strašnice',
    address: 'Starostrašnická 151/36, Praha 10-Strašnice',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/kavovymagnatstrasnice',
    web: '',
    review: '',
    preview: '../previews/31.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#32',
    latitude: 50.09831746649582,
    longitude: 14.425472786764571,
    name: 'Cafe Letka',
    address: 'Letohradská 44, Praha 7-Letná',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/cafeletka',
    web: '',
    review: 'https://www.instagram.com/p/Cf6kP-qsBUz/',
    preview: '../previews/32.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station",  "barrier-free", "inside", null]',
  },
  {
    index: '#33',
    latitude: 50.10020847601553,
    longitude: 14.425889396827095,
    name: 'WAF-WAF',
    address: 'M. Horákové 818/52, Praha 7-Holešovice',
    categoryName: 'Restaurace',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/wafwafletna',
    web: '',
    review: 'https://www.instagram.com/p/Cfn6PUZIxcG/',
    preview: '../previews/33.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '[ "mat", "barrier-free", "inside", null]',
  },
  {
    index: '#34',
    latitude: 50.10114641012448,
    longitude: 14.421587745861965,
    name: 'Vegtral',
    address: 'Čechova 243/12, Praha 7-Bubeneč',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/vegtral',
    web: '',
    review: '',
    preview: '../previews/34.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: 'null',
    outside: '"outside"',
    filters: '[ "mat", "barrier-free",  "outside"]',
  },
  {
    index: '#35',
    latitude: 50.102302786389664,
    longitude: 14.430128640736863,
    name: 'Panda Play',
    address: 'Veletržní 24, Praha 7-Holešovice',
    categoryName: 'Herna',
    category: 'playroom',
    instagram: '',
    facebook: 'https://www.facebook.com/pandaplaystromovka',
    web: '',
    review: '',
    preview: '../previews/35.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station", "mat", "barrier-free", "inside", null]',
  },
  {
    index: '#36',
    latitude: 50.07556268997953,
    longitude: 14.438020240736064,
    name: 'Bruxx',
    address: 'Náměstí Míru 9, Praha 2-Vinohrady',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/restauracebruxx',
    web: '',
    review: '',
    preview: '../previews/36.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[ "mat",  "inside", null]',
  },
  {
    index: '#37',
    latitude: 50.07543832636447,
    longitude: 14.438329596630323,
    name: 'Vinohradský Parlament',
    address: 'Korunní 1, Praha 2-Vinohrady',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/restauracevinohradskyparlament',
    web: 'https://www.vinohradskyparlament.cz/pro-deti/',
    review: '',
    preview: '../previews/37.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[ "mat",  "inside", null]',
  },
  {
    index: '#38',
    latitude: 50.06675763797763,
    longitude: 14.422520232215136,
    name: 'Potrefená husa Albertov',
    address: 'Na Slupi 2102, Praha 2-Nové Město',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/PHAlbertov',
    web: 'https://www.phalbertov.cz/',
    review: 'https://www.instagram.com/p/CgBp2F5IYxP/',
    preview: '../previews/38.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '["changing-station", "mat", "barrier-free", "inside", "outside"]',
  },
  {
    index: '#39',
    latitude: 50.091381311489684,
    longitude: 14.421025256079188,
    name: 'Asian Temple',
    address: 'Bílkova 864, Praha 1-Staré Město',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/asian_temple/',
    facebook: 'https://www.facebook.com/AsianTemple',
    web: 'https://www.asiantemple.cz/cs/uvod/',
    review: '',
    preview: '../previews/39.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[ "mat",  "inside", null]',
  },
  {
    index: '#40',
    latitude: 49.98492838187505,
    longitude: 14.35991161264582,
    name: 'Králíček / Kavárna pro všechny',
    address: 'nám. Osvoboditelů 1371, Radotín',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/kralicekradotin',
    web: 'https://kavarnakralicek.cz/',
    review: '',
    preview: '../previews/40.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#41',
    latitude: 49.964563188630635,
    longitude: 14.459933642245755,
    name: 'Pizza Restaurant Periferie',
    address: 'Pražská 636, Dolní Břežany',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/periferiepizzarestaurant',
    web: 'https://www.periferierestaurant.cz/',
    review: '',
    preview: '../previews/41.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[   "inside", "outside"]',
  },
  {
    index: '#42',
    latitude: 50.036986991811155,
    longitude: 14.333900169570763,
    name: 'Restaurace Kastrol',
    address: 'Ohradské nám. 1625/2, Praha 5',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/restaurace.kastrol',
    web: '',
    review: '',
    preview: '../previews/42.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#43',
    latitude: 50.055783604900924,
    longitude: 14.512539327242546,
    name: 'Kavárna Jasmínka',
    address: 'Jasmínová 37, Praha 10',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/kavarnajasminka',
    web: 'http://www.kavarnajasminka.cz/',
    review: '',
    preview: '../previews/43.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[ "mat", "barrier-free", "inside", "outside"]',
  },
  {
    index: '#44',
    latitude: 50.0444231255195,
    longitude: 14.567894907379708,
    name: 'Bageterie Boulevard',
    address: 'Hornoměcholupská 764, Praha 15',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/bageterieboulevard/',
    facebook: '',
    web: '',
    review: 'https://www.instagram.com/p/CfMZfOtr-OB/',
    preview: '../previews/44.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[ "mat", "barrier-free", "inside", "outside"]',
  },
  {
    index: '#45',
    latitude: 50.032885622396535,
    longitude: 14.596607111902067,
    name: 'Ha Long',
    address: 'Přátelství 1300/44, Praha 22-Uhříněves',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/halongcz/',
    web: 'https://www.halongrestaurant.cz/',
    review: '',
    preview: '../previews/45.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: 'null',
    outside: '"outside"',
    filters: '[  "barrier-free",  "outside"]',
  },
  {
    index: '#46',
    latitude: 50.01224001273595,
    longitude: 14.603465031260463,
    name: 'Tetu Brissy ',
    address: 'Květnového povstání 194, Praha-Benice',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: 'https://www.instagram.com/tetubrissy/',
    facebook: '',
    web: 'http://www.tetubrissy.cz/',
    review: 'https://www.instagram.com/p/CiAVRQnoCdY/',
    preview: '../previews/46.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station",  "barrier-free", "inside", null]',
  },
  {
    index: '#47',
    latitude: 49.937947595365486,
    longitude: 14.553316711437478,
    name: 'Zas a Znova',
    address: 'Hlavní 813Hlavní 813, Sulice – Hlubočinka',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/zasaznova.cz/',
    web: '',
    review: '',
    preview: '../previews/47.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#48',
    latitude: 49.97017193255755,
    longitude: 14.57578658457259,
    name: 'Stodola Herink',
    address: 'Hlavní 7, Herink',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/StodolaHerink',
    web: '',
    review: '',
    preview: '../previews/48.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#49',
    latitude: 49.988362140254885,
    longitude: 14.712918455614092,
    name: 'Villa Vojkov',
    address: 'K Nemocnici 44, Vojkov',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/VillaVojkov',
    web: '',
    review: '',
    preview: '../previews/49.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#50',
    latitude: 50.08177017326727,
    longitude: 14.44676041534031,
    name: 'Taverna Olympos',
    address: 'Kubelíkova 25/9, Praha 3-Žižkov',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/olympos1997',
    web: '',
    review: '',
    preview: '../previews/50.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[   "inside", "outside"]',
  },
  {
    index: '#51',
    latitude: 50.020290835556395,
    longitude: 14.499038764417836,
    name: 'Pivovar Spojovna',
    address: 'U Kunratického lesa 1801, Praha-Kunratice',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/pivovarspojovna',
    web: '',
    review: '',
    preview: '../previews/51.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#52',
    latitude: 50.04827411255241,
    longitude: 14.408334589222461,
    name: 'Jelica',
    address: 'Na Zlíchově 35, Praha 5',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/jelica.cz',
    web: '',
    review: '',
    preview: '../previews/52.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#53',
    latitude: 50.10378137766818,
    longitude: 14.408303695103168,
    name: 'V Zahradě',
    address: 'Schwaigerova 59/3, Praha 6-Bubeneč',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/jimevzahrade',
    web: '',
    review: '',
    preview: '../previews/53.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#54',
    latitude: 50.04747228386656,
    longitude: 14.428014204536428,
    name: 'Na Pekařce',
    address: 'Pod Pekařkou 224, Praha 4-Podolí',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/napekarce',
    web: '',
    review: '',
    preview: '../previews/54.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: '"outside"',
    filters: '[   "inside", "outside"]',
  },
  {
    index: '#55',
    latitude: 50.08714851921071,
    longitude: 14.468958806746498,
    name: 'Cafe spirit',
    address: 'Basilejské nám. 1, Praha 3-Žižkov',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/cafespiritzizkov',
    web: '',
    review: '',
    preview: '../previews/55.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#56',
    latitude: 50.037248348160354,
    longitude: 14.412237160718501,
    name: 'Periferie Cafe',
    address: 'Branická 473/49, Praha 4',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/PeriferieCafe',
    web: '',
    review: '',
    preview: '../previews/56.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#57',
    latitude: 50.03597655941274,
    longitude: 14.412775685309494,
    name: 'Restaurace Trezor',
    address: 'Branická 65/46, Praha 4',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/restauracetrezor',
    web: '',
    review: '',
    preview: '../previews/57.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#58',
    latitude: 50.06006557237581,
    longitude: 14.423814308596162,
    name: 'TA KAVÁRNA',
    address: 'Na Topolce 1b, Praha 4-Nusle',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/takavarna',
    web: '',
    review: '',
    preview: '../previews/58.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#59',
    latitude: 49.99306460128388,
    longitude: 14.463600877910837,
    name: 'Café Jasoň',
    address: 'Putimská 716/4, Praha-Libuš',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/kavarnajason',
    web: '',
    review: '',
    preview: '../previews/59.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#60',
    latitude: 50.04213908068611,
    longitude: 14.459806008596168,
    name: 'Pizzacompany',
    address: 'Budějovická 615/47, Praha 4-Krč',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: '',
    web: '',
    review: '',
    preview: '../previews/60.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#61',
    latitude: 50.0459443507044,
    longitude: 14.448350450924835,
    name: 'Pinoy Club',
    address: 'Budějovická 1123/13, Praha 4-Michle',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/pinoyprague',
    web: '',
    review: '',
    preview: '../previews/61.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#62',
    latitude: 50.056955313800046,
    longitude: 14.42095537572946,
    name: 'Cheecup',
    address: 'Nedvědovo nám. 14, Praha 4-Podolí',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/Cheecup',
    web: '',
    review: '',
    preview: '../previews/62.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#63',
    latitude: 50.07172793678864,
    longitude: 14.401768550924835,
    name: 'KOBE',
    address: 'Radlická 3185, Praha 5-Anděl',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/KobePrague',
    web: '',
    review: '',
    preview: '../previews/63.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#64',
    latitude: 50.08796894928456,
    longitude: 14.342238923938833,
    name: 'Sladká dílna',
    address: 'Na Petřinách 310/82, Praha 6',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/sladkadilna',
    web: '',
    review: '',
    preview: '../previews/64.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#65',
    latitude: 50.08950972032389,
    longitude: 14.385275311643339,
    name: 'Halabala cafe & baby',
    address: 'Hládkov 686/3, Praha 6-Střešovice',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/halabalacafebaby',
    web: '',
    review: '',
    preview: '../previews/65.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#66',
    latitude: 50.08633627357888,
    longitude: 14.345108121757459,
    name: 'Děravý Kotlík',
    address: 'Brunclikova 17, Praha 6',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/DeravyKotlik',
    web: '',
    review: '',
    preview: '../previews/66.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#68',
    latitude: 50.112746303241366,
    longitude: 14.474957604896835,
    name: 'La Cravatta',
    address: 'Braunerova 5, Praha 8',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/La.Cravatta.divadlo.kavarna.bar',
    web: '',
    review: '',
    preview: '../previews/67.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#69',
    latitude: 50.14014809693703,
    longitude: 14.450537550924833,
    name: 'A posto trattoria',
    address: 'Brunclikova 17, Praha 7',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/apostotrattoria',
    web: '',
    review: '',
    preview: '../previews/68.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#70',
    latitude: 50.12128405047024,
    longitude: 14.501594091072128,
    name: 'Kavárna Knoflík',
    address: 'Jablonecká 723/4, Praha 9-Prosek',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/kavarnaknoflik',
    web: '',
    review: '',
    preview: '../previews/69.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#71',
    latitude: 50.12566134910303,
    longitude: 14.502382119907791,
    name: 'Pec nam spadla',
    address: 'Lovosická 21, Praha 9-Prosek',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/pecnamspadla1',
    web: '',
    review: '',
    preview: '../previews/70.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#72',
    latitude: 50.02737466495972,
    longitude: 14.515799391403835,
    name: 'Komunitní centrum a kavárna MEZI DOMY',
    address: 'Křejpského 1502, Praha 11-Chodov',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/mezi.domy',
    web: '',
    review: 'https://www.instagram.com/p/CiRpZcDMdtq/',
    preview: '../previews/71.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '["changing-station", "mat", "barrier-free", "inside", "outside"]',
  },
  {
    index: '#73',
    latitude: 50.02606797690726,
    longitude: 14.516301435250458,
    name: 'Pizza Grande Jižní Město',
    address: 'Křejpského 2276/6A, Praha 11-Chodov',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/pizzavpraze',
    web: '',
    review: '',
    preview: '../previews/72.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#74',
    latitude: 50.0177793418627,
    longitude: 14.442064664417831,
    name: 'Hostinec na Kamýku',
    address: 'Krhanická 994/994 / 21a, Praha 12-Kamýk',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/hostinecnakamyku',
    web: '',
    review: '',
    preview: '../previews/73.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#75',
    latitude: 49.973533651887806,
    longitude: 14.399407935582166,
    name: 'U Posledního Kelta',
    address: 'Závist 1161, Praha 5',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/uposlednihokelta',
    web: '',
    review: '',
    preview: '../previews/74.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#76',
    latitude: 50.08226254434634,
    longitude: 14.560109469306962,
    name: 'Cukrárna Tea',
    address: 'U Hostavického potoka 830, Praha 14',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/profile.php?id=100042666459587',
    web: '',
    review: '',
    preview: '../previews/75.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#77',
    latitude: 50.10215385571974,
    longitude: 14.57965964874346,
    name: 'Plechárna',
    address: 'Bryksova 1002/20, Praha 14-Černý Most',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/plecharnacernymost',
    web: '',
    review: '',
    preview: '../previews/76.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#78',
    latitude: 50.353553206239376,
    longitude: 14.473729296300668,
    name: 'Café Živel',
    address: 'Ostruhova 58, Mělník',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/cafezivel/',
    facebook: 'https://www.facebook.com/cafezivel',
    web: '',
    review: 'https://www.instagram.com/p/CgRSWikIM1G/',
    preview: '../previews/77.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '["changing-station", "mat", "barrier-free", "inside", "outside"]',
  },
  {
    index: '#79',
    latitude: 49.992065239456196,
    longitude: 14.657597614121348,
    name: 'Café Gusto',
    address: 'Masarykovo nám. 35/4, Říčany',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/cafegustoricany/',
    facebook: 'https://www.facebook.com/cafegustoricany',
    web: '',
    review: 'https://www.instagram.com/p/CgWG_uvMIY_/',
    preview: '../previews/78.jpeg',
    changingStation: 'null',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '[ "mat", "barrier-free", "inside", null]',
  },
  {
    index: '#80',
    latitude: 50.00285126984716,
    longitude: 14.564630639034926,
    name: 'Sokolovna Průhonice',
    address: 'Říčanská 118, Průhonice',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/sokolovnapruhonice',
    web: '',
    review: '',
    preview: '../previews/79.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '["changing-station",    "outside"]',
  },
  {
    index: '#81',
    latitude: 49.16877396829066,
    longitude: 16.56968301183446,
    name: 'Pizzeria Ristorante La Patas',
    address: 'U Leskavy 39, Brno-Starý Lískovec',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/lapatas.cz',
    web: '',
    review: '',
    preview: '../previews/80.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#82',
    latitude: 49.13419263140772,
    longitude: 16.613284999983023,
    name: 'Like-It Restaurant',
    address: 'Benešova 166, Modřice',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/LIKEITRESTAURANT',
    web: '',
    review: '',
    preview: '../previews/81.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#83',
    latitude: 49.18267166348991,
    longitude: 16.684840896857068,
    name: 'Cukrárna u myšky',
    address: 'Zlínská 1329/12, Slatina',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/umyskykavarna',
    web: '',
    review: '',
    preview: '../previews/82.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#84',
    latitude: 49.21548907995771,
    longitude: 16.678536423342745,
    name: 'Kafé Mlýnek',
    address: 'Kotlanova 2162/5, Brno-Líšeň',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/mlynek.kafe',
    web: '',
    review: '',
    preview: '../previews/83.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#85',
    latitude: 49.21060857294411,
    longitude: 16.67782800365431,
    name: 'RoSSa Cafe',
    address: 'Zikova 2467/34, Brno-Líšeň',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/rossacafekavarna',
    web: '',
    review: '',
    preview: '../previews/84.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#86',
    latitude: 49.22590895426271,
    longitude: 16.643347277095586,
    name: 'KaFárna',
    address: 'Wágnerova 3b, Brno-Maloměřice a Obřany',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/profile.php?id=100050883829044',
    web: '',
    review: '',
    preview: '../previews/85.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: 'z',
    latitude: 49.22171035577515,
    longitude: 16.620304670242263,
    name: 'Kafe na písku - kávový kutloch',
    address: 'nám. SNP 1771/33, Brno-sever-Černá Pole',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/kafenapisku',
    web: '',
    review: '',
    preview: '../previews/86.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#88',
    latitude: 49.21966468221619,
    longitude: 16.588783177095586,
    name: "Butcher's Grill & Pasta",
    address: 'Dobrovského 29, Brno-Královo Pole',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/butchersbrno',
    web: '',
    review: '',
    preview: '../previews/87.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#89',
    latitude: 49.19802030738308,
    longitude: 16.594448002327486,
    name: 'MAMA KAFE',
    address: 'Úvoz 525/21, Brno-střed-Staré Brno',
    categoryName: 'Herna',
    category: 'playroom',
    instagram: '',
    facebook: 'https://www.facebook.com/mamakafe',
    web: '',
    review: 'https://www.instagram.com/p/Ch7XOjLoqhC/',
    preview: '../previews/88.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station", "mat", "barrier-free", "inside", null]',
  },
  {
    index: '#90',
    latitude: 49.19858116204463,
    longitude: 16.601486118524694,
    name: 'Café Mezzanine',
    address: 'Údolní 15, Brno-střed',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/profile.php?id=100049090130977',
    web: '',
    review: 'https://www.instagram.com/p/CiK1-0hMt_1/',
    preview: '../previews/89.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station", "mat",  "inside", null]',
  },
  {
    index: '#91',
    latitude: 49.19717441513919,
    longitude: 16.606471104508856,
    name: 'UM café učňovská kavárna',
    address: 'Veselá 166/18, Brno-střed',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/kavarnaceska',
    web: '',
    review: '',
    preview: '../previews/90.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#92',
    latitude: 49.19430974277139,
    longitude: 16.611957990301896,
    name: 'Dětská čajovna a kavárna Mezi Stromy',
    address: 'Josefská 1, Brno-střed',
    categoryName: 'Herna',
    category: 'playroom',
    instagram: '',
    facebook: 'https://www.facebook.com/DetskaCajovna',
    web: '',
    review: '',
    preview: '../previews/91.jpeg',
    changingStation: '"changing-station"',
    mat: '"mat"',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: 'null',
    filters: '["changing-station", "mat", "barrier-free", "inside", null]',
  },
  {
    index: '#93',
    latitude: 49.197688772098594,
    longitude: 16.612322031326237,
    name: 'SAMPALERO - dětská kavárna',
    address: 'Jezuitská 585, Brno-střed-Brno-město',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/Sampalero',
    web: '',
    review: '',
    preview: '../previews/92.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#94',
    latitude: 49.19430946551675,
    longitude: 16.612794100095563,
    name: 'Restaurace REBIO',
    address: 'Orlí 26, Brno',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/Rebio.Sun',
    web: '',
    review: '',
    preview: '../previews/93.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: '"inside"',
    outside: 'null',
    filters: '[   "inside", null]',
  },
  {
    index: '#95',
    latitude: 49.19433524042343,
    longitude: 16.61213410118625,
    name: 'Kavárna Spolek',
    address: 'Orlí 22, Brno-střed',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: '',
    facebook: 'https://www.facebook.com/kavarnaSpolek/',
    web: '',
    review: '',
    preview: '../previews/94.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#96',
    latitude: 49.183337422648854,
    longitude: 16.668101280204553,
    name: 'Restaurace Hnízdo',
    address: 'Kigginsova 2, Slatina',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/RestauraceHnizdo',
    web: '',
    review: '',
    preview: '../previews/95.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#97',
    latitude: 49.225284190589804,
    longitude: 16.624499247500943,
    name: 'La Gamba',
    address: 'Okružní 884, Brno-sever-Lesná',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/pages/Pizzeria-La-Gamba/142543125811732',
    web: '',
    review: '',
    preview: '../previews/96.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#98',
    latitude: 50.440096690248346,
    longitude: 15.813832271625174,
    name: 'Restaurace Ve dvoře',
    address: 'nám. T. G. Masaryka 80, Dvůr Králové nad Labem',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/vedvore.cz',
    web: '',
    review: '',
    preview: '../previews/97.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: 'null',
    filters: '[    null]',
  },
  {
    index: '#99',
    latitude: 50.431349603123714,
    longitude: 15.806279171315976,
    name: 'Black Dogs Pub',
    address: '5. května 847, Dvůr Králové nad Labem',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/Black-Dogs-Pub-329958608337785',
    web: '',
    review: '',
    preview: '../previews/98.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#100',
    latitude: 50.45659260961541,
    longitude: 15.768083636261036,
    name: 'U Kolečka',
    address: '544 01 Bílá Třemešná, Bílá Třemešná',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/obcerstveniukolecka',
    web: '',
    review: 'https://www.instagram.com/p/Cgd5w7QLhHz/',
    preview: '../previews/99.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: 'null',
    outside: '"outside"',
    filters: '[  "barrier-free",  "outside"]',
  },
  {
    index: '#101',
    latitude: 50.104000264343306,
    longitude: 14.534562700575963,
    name: 'H55',
    address: 'nám. Ve Starém Hloubětíně 198 00, Praha 14',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/hloubetinska55/',
    facebook: 'https://www.facebook.com/Hloubetinska55/',
    web: 'https://www.h55.cz/cz/kavarna',
    review: 'https://www.instagram.com/p/Cg1ADiZL5ZB/',
    preview: '../previews/100.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '["changing-station",  "barrier-free", "inside", "outside"]',
  },
  {
    index: '#102',
    latitude: 50.053771165939075,
    longitude: 14.58008431400779,
    name: 'U Martina',
    address: 'K Vilkám 1182, Praha-Dubeč',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/pizzerieumartina',
    web: 'http://umartina.eu/',
    review: 'https://www.instagram.com/p/Cgvt3CqsAin/',
    preview: '../previews/101.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: 'null',
    inside: 'null',
    outside: '"outside"',
    filters: '[    "outside"]',
  },
  {
    index: '#103',
    latitude: 50.08398113452811,
    longitude: 14.416490564417831,
    name: 'Bistro Café - Vypečená matka',
    address: 'Betlémská 110 00/261/12, Staré Město',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/vypecena_matka/',
    facebook: 'https://www.facebook.com/katka.veverkova.5',
    web: 'https://vypecenamatka.cz/',
    review: 'https://www.instagram.com/p/Cjmz5W4ozvE/',
    preview: '../previews/102.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: '',
    inside: '"inside"',
    outside: '',
    filters: '["inside"]',
  },
  {
    index: '#104',
    latitude: 50.05812345191487,
    longitude: 14.423251658300908,
    name: 'Vždycky Dobrý',
    address: 'Na Dolinách 18/5, Praha 4',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/vzdyckydobry/',
    facebook: 'https://www.facebook.com/vzdyckydobry',
    web: 'http://vzdyckydobry.com/',
    review: '',
    preview: '../previews/103.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: '',
    inside: '"inside"',
    outside: '',
    filters: '["inside"]',
  },
  {
    index: '#105',
    latitude: 50.09206995214314,
    longitude: 14.429809538638759,
    name: 'Fofr Kafe Soukenda',
    address: 'Soukenická 15, Nové Město',
    categoryName: 'Kavárna',
    category: 'cafe',
    instagram: 'https://www.instagram.com/fofrkafe_soukenda/',
    facebook: '',
    web: '',
    review: 'https://www.instagram.com/p/Cnd7tTRL8_2/',
    preview: '../previews/104.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '"outside"',
    filters: '["barrier-free", "inside", "outside"]',
  },
  {
    index: '#106',
    latitude: 50.092482939043,
    longitude: 14.441825833631475,
    name: 'Mlsná kavka',
    address: 'Sokolovská 327/29, Praha 8-Karlín',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: 'https://www.facebook.com/mlsnakavka/',
    web: 'http://www.mlsnakavka.cz/',
    review: '',
    preview: '../previews/105.jpeg',
    changingStation: 'null',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '',
    filters: '[  "barrier-free",  "inside"]',
  },
  {
    index: '#107',
    latitude: 50.0080566456034,
    longitude: 14.575563940274753,
    name: 'XXXLutz restaurace',
    address: 'Pražská 135, Čestlice',
    categoryName: 'Restaurace',
    category: 'restaurant',
    instagram: '',
    facebook: '',
    web: 'https://www.xxxlutz.cz/c/xxxl-restaurace?utm_source=google&utm_medium=organic&utm_campaign=mybusiness',
    review: '',
    preview: '../previews/106.jpeg',
    changingStation: '"changing-station"',
    mat: 'null',
    barrierFree: '"barrier-free"',
    inside: '"inside"',
    outside: '',
    filters: '[ "changing-station", "barrier-free",  "inside"]',
  },
];

export default data;
